export function useOptions() {
  return {
    map<TKey extends string>(
      types: Array<{
        id: string
      } & Record<TKey, string>>,
      key?: TKey,
    ) {
      const options: Array<{
        attrs?: Record<string, boolean | string>
        label: string
        value: string | null
      }> = [{
        value: null,
        label: 'Select a value',
        attrs: {disabled: true},
      }];

      types.forEach(type => options.push({
        value: type.id,
        label: type[key ?? 'title' as TKey],
      }));

      return options;
    },
  };
}
